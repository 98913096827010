.fixedWhatsapp {
	@apply z-[500] fixed bottom-[16px] right-[16px] md:bottom-[24px] md:right-[24px];

	a:link,
	a:visited {
		@apply inline-block p-[16px] rounded-full;
		box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
		&::before {
			content: '';
			@apply absolute top-0 left-0 w-full h-full bg-[#25D366] rounded-full z-[-2];
			transition: transform 0.2s;
		}
		&::after {
			content: '';
			@apply absolute top-0 left-0 w-full h-full bg-layout-secondary rounded-full scale-0 z-[-1];
			transition: transform 0.3s;
		}
		svg {
			@apply w-[48px] h-[48px] fill-layout-secondary;
			transition: fill 0.3s;
			transition-delay: 0.2s;
		}
	}

	a:hover,
	a:active {
		&::before {
			content: '';
			transform: scale(0);
		}
		&::after {
			content: '';
			@apply absolute top-0 left-0 w-full h-full bg-layout-secondary rounded-full scale-100;
		}

		svg {
			@apply fill-layout;
		}
	}
}
