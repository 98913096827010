.customers {
	@apply pt-[16px] sm:pt-[24px] md:pt-[32px] lg:pt-[48px] xl:pt-[64px] pb-[32px] sm:pb-[48px] md:pb-[64px] lg:pb-[96px];

	&__slider {
		@apply relative overflow-hidden mb-[24px] md:mb-[32px];
		white-space: nowrap;

		&:hover {
			.customers__slide {
				animation-play-state: paused;
			}
		}
	}
	&__slide {
		@apply inline-block w-max;
		animation: 20s slide infinite linear;
	}

	&__image {
		@apply inline mx-[6vw];

		&.stretched {
		}
	}

	&__text {
		@apply text-center text-[20px] uppercase text-layout-secondary sm:text-[20px] md:text-[24px] lg:text-[32px];
	}

	//! funcionalidad
}

@keyframes slide {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-100%);
	}
}
