.about {
	@apply relative text-center shadow-section px-[16px] sm:px-[32px] md:px-[48px] lg:px-[64px] py-[32px] sm:py-[48px] md:py-[64px] lg:py-[96px];

	&__bg {
		@apply absolute w-full h-full top-[0] left-[0] z-[-1] object-cover;
		object-position: 50% 10%;
		filter: brightness(30%);
	}

	&__container {
		@apply mx-auto max-w-[400px] sm:max-w-[500px] md:max-w-[500px] lg:max-w-[700px] rounded-[5px];

		h2 {
			@apply mb-[8px] sm:mb-[16px] text-[32px] md:text-[48px] xl:text-[64px] leading-tight font-semibold text-primary;
		}

		p {
			@apply mb-[16px] leading-normal text-[20px] sm:text-[24px] lg:text-[32px] text-layout-secondary font-medium;
		}

		a:link,
		a:visited {
			@apply bg-primary relative inline-block py-[16px] px-[24px] sm:py-[24px] sm:px-[32px]  text-layout text-[20px] uppercase font-semibold mb-[32px] sm:text-[24px] rounded-full;
			box-shadow: 0 6px 16px rgba(0, 0, 0, 0.5);
			transition: background-color 0.3s, transform 0.2s ease-out;

			&:hover,
			&:active {
				@apply bg-primary-dark translate-y-[-2px];
			}
		}
	}
}
